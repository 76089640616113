import React from "react"
import HomepageHero from "../components/Homepage/HomepageHero"
import HomepageIntro from "../components/Homepage/HomepageIntro"
import HomepageSea from "../components/Homepage/HomepageSea"
import HomepageTestimonials from "../components/Homepage/HomepageTestimonials"
import HomepageSeaBottom from "../components/Homepage/HomepageSeaBottom"
import HomepageTeam from "../components/Homepage/HomepageTeam"
import HomepageSignup from "../components/Homepage/HomepageSignup"
import HomepageBlog from "../components/Homepage/HomepageBlog"
import HomepageNews from "../components/Homepage/HomepageNews"

import { motion } from "framer-motion"

import { pageFade, pageTransistion } from "../helpers/FramerMotionAnim"

import MainLayout from "../layouts/MainLayout"
import { Helmet } from "react-helmet"
import ClosingMessage from "../components/Homepage/ClosingMessage"

const Homepage = () => {
  return (
    <MainLayout>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="C.Liu" />

        <title>Homepage | Café Kraken</title>
        <meta
          name="description"
          content="Welcome to Café Kraken, your local, friendly café."
        />
      </Helmet>
      <motion.div
        initial="out"
        animate="in"
        exit="out"
        variants={pageFade}
        transition={pageTransistion}
      >
        <ClosingMessage></ClosingMessage>
        <HomepageHero></HomepageHero>
        <HomepageIntro></HomepageIntro>
        <HomepageSea></HomepageSea>
        <HomepageSignup></HomepageSignup>
        <HomepageSeaBottom></HomepageSeaBottom>
        <HomepageTeam></HomepageTeam>
        <HomepageTestimonials></HomepageTestimonials>
        {/* <HomepageBlog></HomepageBlog> */}
        <HomepageNews></HomepageNews>
      </motion.div>
    </MainLayout>
  )
}

export default Homepage
