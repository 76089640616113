import React from "react"
import logo from "../../assets/images/logo-hero.svg"
import bg from "../../assets/videos/cafe-kraken-hero.mp4"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const HomepageHero = () => {
  const renderTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      Coming Soon!
    </Tooltip>
  )
  return (
    <section className="homepage--hero">
      <div className="homepage--video--container">
        <video
          className="masthead--video"
          playsInline="playsinline"
          autoPlay="autoplay"
          muted="muted"
          loop="loop"
          id="video-landing"
        >
          <source src={bg} type="video/mp4"></source>
        </video>
      </div>

      <div className="video--overlay" id="video-overlay"></div>

      <div className="homepage--hero--content">
        <img
          src={logo}
          alt=""
          className="homepage--hero--content__logo"
          id="hgroup-logo"
        />
        <br />
        <h4 className="text-white py-4 homepage--hero--content__text">
          Oxford's First <br />
          Ecocentric Café
        </h4>
        <p className="p-0 m-0 ">
          <small>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 100, hide: 100 }}
              overlay={renderTooltip}
            >
              <a
                href="https://shop.cafekraken.com/"
                target="_blank"
                className="btn btn--ecru--white"
                onClick={event => event.preventDefault()}
              >
                Order Now
              </a>
            </OverlayTrigger>
          </small>
        </p>
      </div>

      <p className="homepage--hero__openings">
        Wednesday-Sunday 8.00am-4.30pm <br />
      </p>

      <div className="homepage--hero__socials">
        <a href="https://www.facebook.com/cafekrakenoxford/" target="_blank">
          <FontAwesomeIcon icon={["fab", "facebook"]} />
        </a>
        <a href="https://www.instagram.com/cafekrakenuk/" target="_blank">
          <FontAwesomeIcon icon={["fab", "instagram"]} />
        </a>
        <a href="https://twitter.com/cafekraken?lang=en" target="_blank">
          <FontAwesomeIcon icon={["fab", "twitter"]} />
        </a>
      </div>
    </section>
  )
}

export default HomepageHero
