import React, { useState, useEffect } from "react"
import { useInView } from "react-intersection-observer"

const HomepageTestimonials = () => {
  const [ref, inView] = useInView()

  return (
    <section className="homepage--testimonials">
      <div className="container">
        <div
          className="row"
          style={{
            opacity: inView ? 1 : 0,
            transform: `translateY(${inView ? 0 : 100}px)`,
            transition: "all 1s",
          }}
          ref={ref}
        >
          <div className="col-12 col-lg-7">
            <h2 className="homepage--testimonials__q1">
              Simply amazing food - beautifully put together with fresh
              ingredients. Staff are lovely as well, very easy to talk to and
              very friendly.
            </h2>
          </div>

          <div className="col-12 col-lg-5">
            <h2 className="homepage--testimonials__q2">
              "Bright, clean and new spacious space. Nice latte. Courteous and
              friendly staff."
            </h2>

            <h3 className="homepage--testimonials__q3">
              "Great first visit to Café Kraken, a lovely place with fab decor
              and a good menu for vegetarians and vegans."
            </h3>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomepageTestimonials
