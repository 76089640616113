import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import ImgWasteNot from "../../assets/images/Homepage/homepage-waste-not.png"
import ImgSupplier from "../../assets/images/Homepage/homepage-supplier1.png"
import ImgSupplier2 from "../../assets/images/Homepage/homepage-supplier2.png"

import ImgTeam from "../../assets/images/Homepage/homepage-emily.jpg"
import ImgTeam2 from "../../assets/images/Homepage/homepage-team2.png"
import ImgMerch from "../../assets/images/Homepage/homepage-merch.png"

import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

const HomepageTeam = () => {
  const [switcher, setSwitcher] = useState(true)
  const [ref, inView] = useInView()

  function handleSwitch() {
    setSwitcher(!switcher)
  }

  return (
    <section
      className="homepage--team"
      style={{
        opacity: inView ? 1 : 0,
        transform: `translateY(${inView ? 0 : 100}px)`,
        transition: "all 1s",
      }}
      ref={ref}
    >
      <div className="container">
        <div className="row">
          <div className="homepage--team--switcher">
            <form action="">
              <fieldset onChange={event => handleSwitch(event)}>
                <input
                  id="toggle-on"
                  className="toggle toggle-left"
                  name="toggle"
                  type="radio"
                  defaultChecked
                />
                <label htmlFor="toggle-on">Community</label>
                <input
                  id="toggle-off"
                  className="toggle toggle-right"
                  name="toggle"
                  type="radio"
                />
                <label htmlFor="toggle-off">Suppliers</label>
              </fieldset>
            </form>
          </div>
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 4, type: "spring", ease: "easeOut" }}
          key={switcher}
        >
          {switcher ? (
            <div className="homepage--team--left">
              <div className="homepage--team__message">
                <h2 className="pb-3">
                  Our Café For <br /> Your Community
                </h2>
                <p>
                  In a time of digital anonymity, we love to see our customers
                  and have a good chat! We'll always try to put a name to a face
                  if we can, so don't be shy.
                </p>
                <p>
                  Sometimes, all you need is a friendly face to lift your day,
                  and if you're getting a coffee or tasty slice of cake at the
                  same time, what could be better?
                </p>
              </div>
              <img src={ImgTeam2} alt="" className={`homepage--team--photo1`} />

              <img src={ImgTeam} alt="" className={`homepage--team--photo2`} />

              <img src={ImgMerch} alt="" className={`homepage--team--photo3`} />
            </div>
          ) : (
            <div className="homepage--team--right">
              <div className="homepage--team__message">
                <h2 className="pb-3 ">
                  Local Produce, <br /> Less Waste
                </h2>
                <p>
                  We’re proud to team up with local suppliers and firmly believe
                  in supporting other small business’s.
                </p>

                <p>
                  We aim to buy seasonal and organic produce where possible, not
                  only to offer our customers a taste of all the seasons, but to
                  support local farms and reduce demand on out-of-season
                  produce.
                </p>
              </div>

              <img
                src={ImgSupplier2}
                alt=""
                className={`homepage--team--photo1`}
              />

              <img
                src={ImgWasteNot}
                alt=""
                className={`homepage--team--photo2`}
              />

              <img
                src={ImgSupplier}
                alt=""
                className={`homepage--team--photo3`}
              />
            </div>
          )}
        </motion.div>
      </div>
    </section>
  )
}

export default HomepageTeam
