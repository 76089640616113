import React, { useState } from "react"
import logo from "../../assets/images/logo_main.svg"

const ClosingMessage = () => {
  const [show, setShow] = useState(true)

  console.log(show)

  return (
    <div className={` closing-message ${show ? "" : "hide"} `}>
      <img src={logo} className={`pb-5`} alt="dk_logo" />

      <p>Hi everyone, we have some sad news to share with you… </p>

      <p>
        It is with a heavy heart that Cafe Kraken will be closing its doors at
        the end of this month (October 2022). We set out to create a space for
        our community to use as safe, relaxing sanctuary to spend time with
        themselves or loved ones. We believe we succeeded in doing this across
        all generations of friends and families in the Cowley area.
      </p>

      <p>
        Unfortunately, due to the high inflation this year, interest rate rises
        and large increases in energy bills, the cost-of-living crisis has
        impacted our customers as well as ourselves. The number of customers
        coming in has dropped by 40% in the last few months and our costs have
        more than doubled. This regrettably has meant that it is no longer
        financially sustainable to keep the café open. We would like to say a
        big thank you to our customers and the community for making the café the
        special environment it clearly is. We’d also like to extend our
        gratitude to our wonderful suppliers (most of whom are independent local
        businesses too). We’d love the chance to see as many of you as we can
        this weekend and next week, we’re still serving our food menu with
        toasties, a new breakfast wrap, delicious coffee, creamy hot chocolates
        and plenty more. Thank you all.
      </p>

      <p>
        Opening hours: <br /> Wednesday - Friday 8:00 - 16:30
        <br /> Saturday & Sunday 9:00 - 16:30 <br />
        Last day: Sunday the 30th October
      </p>

      <button className="btn" onClick={() => setShow(false)}>
        <h4>✖</h4>
      </button>
    </div>
  )
}

export default ClosingMessage
