import React from "react"
import { Container } from "react-bootstrap"
import { useInView } from "react-intersection-observer"

const HomepageIntro = () => {
  const { ref, inView } = useInView()

  return (
    <section
      className="homepage--intro"
      style={{
        transition: "opacity 2000ms, transform 2000ms",
        opacity: inView ? 1 : 0,
        transform: `translateY(${inView ? 0 : 100}px)`,
        transition: "all 1s",
      }}
      ref={ref}
    >
      <Container>
        <h2>Welcome to Café Kraken, your local, friendly café.</h2>
        <p className="pt-5">
          We are a plant-based café serving delicious vegetarian and vegan food
          and fantastic coffee.
          <br />
          <br />
          We try to support local Oxforshire based businesses to keep our carbon
          footprint as small as possible.
          <br />
          <br />
          Our café features acoustic glass windows to silence the local hubbub
          and a natural moss ceiling to soak up excess noise, an oasis of calm
          from the outside world.
        </p>
      </Container>
    </section>
  )
}

export default HomepageIntro
