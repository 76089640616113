import React from "react"
import imgEcoWorld from "../../assets/images/icons/eco-world.svg"

import ImgCoffee from "../../assets/images/Homepage/awesomecoffee.png"
import ImgPotato from "../../assets/images/Homepage/homepage-potato.jpg"
import ImgLemonCake from "../../assets/images/Homepage/homepage-lemon-cake.jpg"
import ImgFoods from "../../assets/images/Homepage/fantasticfoods.png"
import ImgCyclists from "../../assets/images/Homepage/cyclists.png"
import ImgDog from "../../assets/images/Homepage/dogfriendly.png"

import ImgLine from "../../assets/images/Homepage/line.svg"
import ImgCake from "../../assets/images/Homepage/cake.png"

import ImgCoffeeIcon from "../../assets/images/Homepage/coffee.png"
import ImgSandwichIcon from "../../assets/images/Homepage/sandwich.png"
import ImgBicycleIcon from "../../assets/images/Homepage/bicycle.png"
import ImgDogIcon from "../../assets/images/Homepage/dog.png"

import { useInView } from "react-intersection-observer"

const HomepageSeaBottom = () => {
  const [ref, inView] = useInView()
  const [ref2, inView2] = useInView()
  const [ref3, inView3] = useInView()
  const [ref4, inView4] = useInView()

  return (
    <section className="homepage--sea--bottom">
      <div className="container">
        <div className="row homepage--sea--bottom__coffee">
          <div className="col-12 col-lg-5">
            <div className="homepage--sea--bottom__container">
              <img
                src={ImgCoffee}
                alt=""
                className={`homepage--sea--bottom__coffee__img ${
                  inView ? "active" : ""
                }`}
                ref={ref}
              ></img>

              <img
                src={ImgLine}
                alt=""
                className={`line--coffee ${inView ? "active" : ""}`}
              />
              <div className="homepage--sea--bottom__text">
                <img src={ImgCoffeeIcon} alt="" className="icon" />
                <h2>Awesome Coffee</h2>
                <p className="pt-2">
                  Our expert baristas will make your favourite coffee using
                  beans from Newground Coffee, ethically sourced and expertly
                  roasted in Oxford. Of course, you can enjoy one of our finest
                  leaf teas from Teasup, a small UK-based speciality Tea
                  supplier. <br />
                  <br /> We offer a wide range of milk for our coffee such as
                  cow’s milk, oat, soy or coconut milk.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row homepage--sea--bottom__cake">
          <div className="col-12 offset-lg-7 col-lg-5">
            <div className="homepage--sea--bottom__container">
              <img
                src={ImgFoods}
                alt=""
                className={`homepage--sea--bottom__cake__img ${
                  inView2 ? "active" : ""
                }`}
                ref={ref2}
              />

              <img
                src={ImgLine}
                alt=""
                className={`line-left ${inView2 ? "active" : ""}`}
              />
              <div className="homepage--sea--bottom__text--left">
                <img src={ImgSandwichIcon} alt="" className="icon" />

                <h2>Fantastic Foods</h2>
                <p className="pt-2">
                  We have developed our tasty plant-based meals and have plenty
                  of Vegan options. We make everything to order at the café. You
                  can quickly adapt every menu item to your specific dietary
                  requirement. <br />
                  <br /> So if you need breakfast, lunch or just a coffee and a
                  cake, you will always find something you love at Café Kraken.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row homepage--sea--bottom__coffee">
          <div className="col-12 col-lg-5">
            <div className="homepage--sea--bottom__container">
              <img
                src={ImgCyclists}
                alt=""
                className={`homepage--sea--bottom__coffee__img ${
                  inView3 ? "active" : ""
                }`}
                ref={ref3}
              ></img>

              <img
                src={ImgLine}
                alt=""
                className={`line--coffee ${inView3 ? "active" : ""}`}
              />
              <div className="homepage--sea--bottom__text">
                <img src={ImgBicycleIcon} alt="" className="icon" />
                <h2>Cyclist Cafe</h2>
                <p className="pt-2">
                  Our Founder Gope is a cycling fanatic, so it won’t surprise
                  you that we love cyclists. Café Kraken is the perfect spot to
                  take a break when cycling around Oxford, touring through the
                  Thames Valley or out with your cycling club.
                  <br />
                  <br />
                  Please let us know if your peloton plans to stop by, and we
                  will make sure we are ready to serve you quickly.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row homepage--sea--bottom__dog">
          <div className="col-12 offset-lg-6 col-lg-6">
            <div className="homepage--sea--bottom__container">
              <img
                src={ImgDog}
                alt=""
                className={`homepage--sea--bottom__dog__img ${
                  inView4 ? "active" : ""
                }`}
                ref={ref4}
              />

              <img
                src={ImgLine}
                alt=""
                className={`line-left ${inView4 ? "active" : ""}`}
              />
              <div className="homepage--sea--bottom__text--left">
                <img src={ImgDogIcon} alt="" className="icon" />

                <h2>Dog-Friendly Café</h2>
                <p className="pt-2">
                  Your furry friend is welcome to join you at Café Kraken as we
                  welcome dogs with their well-behaved owners.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomepageSeaBottom
