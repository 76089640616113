import React, { Component, useState } from "react"
import { render } from "react-dom"
import { Container } from "react-bootstrap"
import ImgFeature from "../../assets/images/Homepage/homepage-feature.jpg"
import ImgCoffeePour from "../../assets/images/Homepage/homepage-coffee-pour.png"
import ImgSelection from "../../assets/images/Homepage/homepage-selection.jpg"

import ImgMountain from "../../assets/images/Homepage/mountain2.png"

import ImgOrderInStore from "../../assets/images/icons/order-in-store.svg"
import ImgDeliveroo from "../../assets/images/icons/deliveroo-logo.svg"

import { motion, useAnimation } from "framer-motion"
import { pageFade, pageTransistion } from "../../helpers/FramerMotionAnim"

import { useInView } from "react-intersection-observer"
import { Parallax } from "react-scroll-parallax"

import { OutboundLink } from "gatsby-plugin-google-gtag"

import MailchimpSubscribe from "react-mailchimp-subscribe"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const HomepageIntro = () => {
  const { ref, inView } = useInView({
    threshold: 0,
  })

  const [ref1, inView1] = useInView({
    threshold: 0.1,
  })

  const animation = useAnimation()

  return (
    <section className="homepage--sea">
      <div className="container" ref={ref}>
        <motion.div
          animate={animation}
          variants={pageFade}
          transition={pageTransistion}
        >
          <img
            src={ImgFeature}
            alt=""
            className={`homepage--sea__center ${inView ? "active" : ""}`}
            id="center-img"
          />
        </motion.div>

        <motion.div
          animate={animation}
          variants={pageFade}
          transition={pageTransistion}
        >
          <img
            src={ImgCoffeePour}
            alt=""
            className={`homepage--sea__left ${inView1 ? "active" : ""}`}
          />
        </motion.div>

        <motion.div
          animate={animation}
          variants={pageFade}
          transition={pageTransistion}
        >
          <img
            src={ImgSelection}
            alt=""
            className={`homepage--sea__right ${inView1 ? "active" : ""}`}
          />
        </motion.div>

        <div ref={ref1}>
          <Parallax className="custom-class" y={[2, 4]}>
            <p className={`homepage--sea__text ${inView1 ? "active" : ""}`}>
              We monitor and honestly report our wastage, source as many local
              and sustainable ingredients for all our products. In continuity
              with our ethics, we try to make the majority of our products on
              site such as our beloved vegan garlic mayo or our hummus for
              example.
              <br />
              <br />
              This allows us to take a look into all aspects of menu creation,
              not just ingredients but the entire process, which can be
              streamlined effectively to make sure our menu is as eco-centric as
              possible.
              <br />
              <br />
              Available for order in store or delivery.
              <div className="row pt-5">
                <div className="col-6">
                  <OutboundLink
                    href="https://shop.cafekraken.com/"
                    target="_blank"
                  >
                    <img src={ImgOrderInStore} alt="" className="w-100 p-2" />
                  </OutboundLink>
                </div>
                <div className="col-6 ">
                  <OutboundLink
                    href="https://deliveroo.co.uk/menu/oxford/cowley/cafe-kraken"
                    target="_blank"
                  >
                    <img src={ImgDeliveroo} alt="" className="w-100 p-2" />
                  </OutboundLink>
                </div>
              </div>
            </p>
          </Parallax>
        </div>
      </div>
    </section>
  )
}

export default HomepageIntro
