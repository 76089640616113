import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useInView } from "react-intersection-observer"
import imgInsta from "../../assets/images/Insta/closing.jpeg"
import img2 from "../../assets/images/Insta/2.jpg"
import img3 from "../../assets/images/Insta/3.jpg"
import img4 from "../../assets/images/Insta/4.jpg"

const HomepageNews = () => {
  // const instaData = useStaticQuery(graphql`
  //   query {
  //     allCustomInstaNode {
  //       edges {
  //         node {
  //           id
  //           mediaURL
  //           caption
  //           permalink
  //           media_type
  //         }
  //       }
  //     }
  //   }
  // `).allCustomInstaNode.edges

  const [ref, inView] = useInView()
  return (
    <section
      className="homepage--news"
      style={{
        opacity: inView ? 1 : 0,
        transform: `translateY(${inView ? 0 : 100}px)`,
        transition: "all 1s",
      }}
      ref={ref}
    >
      <div className="container">
        <h2 className="py-5 text-center">Follow Our Instagram</h2>
        <p className="text-center pb-5">
          <a
            href="https://www.instagram.com/cafekrakenuk/"
            target="_blank"
            className="text-decoration-none"
          >
            <p>@cafekrakenuk</p>
          </a>
        </p>

        <div className="gallery" id="gallery-1">
          <div className="gallery__column" id="gallery-1-0">
            <a
              href="https://www.instagram.com/cafekrakenuk/"
              target="_blank"
              className="gallery__link"
            >
              <figure className="gallery__thumb">
                <img
                  id={17978069173741174}
                  src={imgInsta}
                  alt="We hope to see you before we go, thank you for all your kind words and support 💚"
                  className="gallery__image"
                />
                <figcaption className="gallery__caption">
                  We hope to see you before we go, thank you for all your kind
                  words and support 💚...
                </figcaption>
              </figure>
            </a>
          </div>
          <div className="gallery__column" id="gallery-1-1">
            <a
              href="https://www.instagram.com/cafekrakenuk/"
              target="_blank"
              className="gallery__link"
            >
              <figure className="gallery__thumb">
                <img
                  id={18037377118369446}
                  src={img2}
                  alt="Lovely visit from the @berkshiretrisquad on Saturday! 🚲 

They were out for a nice long ride in the sun, and we were happy to provide food shelter and cold coffee for their rest break! 🧊 💦 

Thanks for coming guys, we’re looking forward to having you back!

#BerkshireTriSquad #BTS #TriathlonTraining #LocalCafe #JustKeepCycling #CowleyCyclists #OxfordCoffee #SustainableCafe"
                  className="gallery__image"
                />
                <figcaption className="gallery__caption">
                  Lovely visit from the @berkshiretrisquad on Saturday! 🚲 They
                  were out for a nice long ride in the sun, and we were happy to
                  provide food shelter an...
                </figcaption>
              </figure>
            </a>
          </div>
          <div className="gallery__column" id="gallery-1-2">
            <a
              href="https://www.instagram.com/cafekrakenuk/"
              target="_blank"
              className="gallery__link"
            >
              <figure className="gallery__thumb">
                <img
                  id={17974100725564686}
                  src={img3}
                  alt="🚨 NEW COMMUNITY ART 🚨 

We’d like to extend an enormous thank you to Tyndale School’s year 1 classes for producing this INCREDIBLE piece of art for our wall!
🌱 
We were delighted to help them out with their healthy eating and sustainability project, which involved both classes coming to visit us to do some research into their local Cafe and how we are working towards our Green goals and our #SustainabilityJourney !
👨🏽‍🎓 👩🏻‍🎓 
You guys were a delight to have in the cafe, and thank you to all parents and friends who came to the unveiling yesterday!
🖼 
A special thank you & congratulations to Faith for putting this whole thing on and pulling off a wicked project! A true #LocalHero and #HeroEducator 
.
#Localbusiness #shopsmall #bringbackthehighstreet #sustainablecoffee #oxfordcoffee #cowleycoffee"
                  className="gallery__image"
                />
                <figcaption className="gallery__caption">
                  🚨 NEW COMMUNITY ART 🚨 We’d like to extend an enormous thank
                  you to Tyndale School’s year 1 classes for producing this
                  INCREDIBLE piece of art for ...
                </figcaption>
              </figure>
            </a>
          </div>
          <div className="gallery__column" id="gallery-1-3">
            <a
              href="https://www.instagram.com/cafekrakenuk/"
              target="_blank"
              className="gallery__link"
            >
              <figure className="gallery__thumb">
                <img
                  id={18142898116256027}
                  src={img4}
                  alt="To our dear customers,

Thank you for your continued support for Cafe Kraken. 🥹 

It is with a heavy heart that we are announcing our kitchen will be closing from next week - and that we are going back down to 5 days (Wednesday - Sunday).

Unfortunately, due to the current economic and employment situation, we are having difficulty with staffing the cafe. 

If you - or anyone you know - is looking for an exciting chef role, please reach out to us by instagram, or email your CV to hello@cafekraken.com 📧 

We will still be open Wednesday to Sunday serving coffee, teas, iced lattes, cakes and pastries!

#CafeKraken #NowHiring #NewHours #OpeningHours #OxfordCoffee #OxfordBaristas #OxfordChefs #WorkWithUs #SustainableCoffee #SustainableRestaurants"
                  className="gallery__image"
                />
                <figcaption className="gallery__caption">
                  To our dear customers, Thank you for your continued support
                  for Cafe Kraken. 🥹 It is with a heavy heart that we are
                  announcing our kitchen will be...
                </figcaption>
              </figure>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomepageNews
