import React, { Component, useState } from "react"
import { render } from "react-dom"
import { Container } from "react-bootstrap"
import ImgFeature from "../../assets/images/Homepage/homepage-feature.jpg"
import ImgCoffeePour from "../../assets/images/Homepage/homepage-coffee-pour.png"
import ImgSelection from "../../assets/images/Homepage/homepage-selection.jpg"

import ImgMountain from "../../assets/images/Homepage/mountain2.png"

import ImgOrderInStore from "../../assets/images/icons/order-in-store.svg"
import ImgDeliveroo from "../../assets/images/icons/deliveroo-logo.svg"

import { motion, useAnimation } from "framer-motion"
import { pageFade, pageTransistion } from "../../helpers/FramerMotionAnim"

import { useInView } from "react-intersection-observer"
import { Parallax } from "react-scroll-parallax"

import { OutboundLink } from "gatsby-plugin-google-gtag"

import MailchimpSubscribe from "react-mailchimp-subscribe"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const HomepageSignup = () => {
  const [ref2, inView2] = useInView({
    threshold: 0.1,
  })
  const url =
    "https://cafekraken.us20.list-manage.com/subscribe/post?u=074845b45c436af6e1818e536&amp;id=1ffedf8597"

  const CustomForm = ({ status, message, onValidated }) => {
    const [birthday, setBirthday] = useState("")
    let email, name, day, month

    const submit = () => {
      day = birthday ? birthday.getUTCDate() : ""
      month = birthday ? birthday.getUTCMonth() + 1 : ""

      email &&
        name &&
        email.value.indexOf("@") > -1 &&
        onValidated({
          EMAIL: email.value,
          FNAME: name.value.split(/ (.+)/)[0],
          LNAME: name.value.split(/ (.+)/)[1],
          "BIRTHDAY[month]": month,
          "BIRTHDAY[day]": day,
        })
    }

    return (
      <div
        style={{
          display: "inline-block",
        }}
      >
        <div className="row pt-2">
          <div className="col-sm-12 col-lg-6">
            <p>
              <input
                type="text"
                placeholder="Name *"
                ref={node => (name = node)}
              />
            </p>
          </div>

          <div className="col-sm-12 col-lg-6">
            <p>
              <input
                ref={node => (email = node)}
                type="email"
                placeholder="Email *"
                required
              />
            </p>
          </div>
          <div className="col-sm-12 col-lg-6 pt-4 birthday">
            <p>
              <DatePicker
                selected={birthday}
                onChange={date => setBirthday(date)}
                maxDate={new Date()}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="Birthday"
                dateFormat="dd/MM/yyyy"
              />
            </p>
          </div>
          <div className="col-sm-12 col-lg-6 pt-4">
            <div className="d-flex align-items-end justify-content-end">
              <p>
                <button
                  onClick={submit}
                  className="btn btn--persian--green my-4 d-inline-block"
                >
                  Sign Up
                </button>
              </p>
            </div>

            <div className="text-right d-flex align-items-end justify-content-end pt-2">
              {status === "sending" && (
                <div className="text-persian-green">sending...</div>
              )}
              {status === "error" && (
                <div
                  className="text-persian-green"
                  dangerouslySetInnerHTML={{ __html: message }}
                />
              )}
              {status === "success" && (
                <div
                  className="text-persian-green"
                  dangerouslySetInnerHTML={{ __html: message }}
                />
              )}
            </div>
          </div>
        </div>

        <br />
      </div>
    )
  }

  return (
    <section className="homepage--signup" id="signup">
      <div className="row mailing-list--container" ref={ref2}>
        <div
          className="container mailing-list"
          style={{
            opacity: inView2 ? 1 : 0,
            transform: `translateY(${inView2 ? 0 : 100}px)`,
            transition: "all 1s",
          }}
        >
          <h2 id="signup">Sign up here for a FREE treat - It’s on us! </h2>
          <p className="py-4">
            Sign up to our mailing list and be the first to know about our
            exclusive news and offers. Visit us in store to claim your
            complimentary pastry, brownie or slice of cake with any order.
          </p>

          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={formData => subscribe(formData)}
              />
            )}
          />

          <p className="text-muted terms">
            <small>
              <i>
                Offer must be claimed in person by showing proof of signing up
                to the mailiing list with your welcome email. Treats include
                pastry, brownie (GF) or slice of cake (VG). No minimum spend.
                One time use only.
              </i>
            </small>
          </p>
        </div>

        <img src={ImgMountain} alt="" className="homepage--sea__mountain" />
      </div>
    </section>
  )
}

export default HomepageSignup
